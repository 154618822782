export default [
  { label: "Responsabilité civile de l'entreprise", value: 'RC1' },
  { label: 'Responsabilité civile du dirigeant', value: 'RC2' },
  { label: 'Multirisque Professionnelle', value: 'RC3' },
  { label: 'Biens professionnels', value: 'DAB' },
  { label: 'Véhicule professionnel', value: 'FLOTTES1' },
  { label: 'Flotte automobile', value: 'FLOTTES2' },
  { label: 'Construction', value: 'CONSTRUCTION' },
  { label: 'Santé des salariés', value: 'SANTE COLL' },
  { label: 'Santé du dirigeant (Madelin)', value: 'SANTE IND' },
  { label: 'Prévoyance des salariés', value: 'PREV COLL' },
  { label: 'Prévoyance du dirigeant (Madelin)', value: 'PREV IND' },
  { label: 'Retraite des salariés', value: 'RETRAITE COLL' },
  { label: 'Retraite du dirigeant', value: 'RETRAITE IND' },
  { label: 'Autre besoin', value: 'DIVERS' },
];
