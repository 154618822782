import React from 'react';
import { InputSelect } from 'timeone-components';
import PropTypes from 'prop-types';
import { Field, FormSpy } from 'react-final-form';

import AppContext from '../../App/App.context';
import { LabelContainer, Label, LabelSpan, LabelError, Optional } from './styles';

function useInput(Component) {
  function requiredTest(value) {
    const isRequiredMessage = 'Le champ doit contenir une valeur.';

    let testResult = null;

    if (Component === InputSelect) {
      testResult =
        !(
          !!value &&
          !Array.isArray(value) &&
          Object.keys(value).length === 2 &&
          Object.prototype.hasOwnProperty.call(value, 'label') &&
          Object.prototype.hasOwnProperty.call(value, 'value')
        ) && isRequiredMessage;
    } else {
      testResult = !(!!value && value.length > 0) && isRequiredMessage;
    }

    return testResult;
  }

  class CustomInput extends Component {
    handleValidate = (value, meta, formProps) => {
      const { setIsFormLocked } = this.context;
      const { validation, required } = this.props;
      const { invalid, errors } = formProps;

      if (meta.error || invalid || Object.entries(errors).length !== 0) {
        setIsFormLocked(true);
      }

      return new Promise(resolve => {
        if (this.clearTimeout) {
          this.clearTimeout();
        }

        const requiredTestResult = requiredTest(value);

        if (required && requiredTestResult) {
          resolve(requiredTestResult || undefined);
        }

        const timerId = setTimeout(async () => {
          if (validation && value) {
            const result = await validation(value);

            if (!result) {
              setIsFormLocked(false);
            }

            resolve(result);
          } else {
            resolve();
          }
        }, 300);

        this.clearTimeout = () => {
          clearTimeout(timerId);
          resolve(undefined);
        };
      });
    };

    render() {
      const { change, className, config, name, label, required } = this.props;

      return (
        <FormSpy subscription={{ values: true, errors: true, invalid: true }}>
          {formProps => (
            <Field
              name={name}
              subscription={{
                touched: true,
                active: true,
                data: true,
                initial: true,
                error: true,
                submitError: true,
                dirtySinceLastSubmit: true,
                values: true,
              }}
            >
              {({ meta }) => (
                <Field
                  name={name}
                  validate={value =>
                    value !== formProps.values[name] || value === formProps.form.getState().initialValues[name]
                      ? this.handleValidate(value, meta, formProps)
                      : meta.error
                  }
                >
                  {({ input }) => (
                    <LabelContainer className={`tol-input-container ${className}`}>
                      <Label className={`${this.handleIsActive(input, meta) ? ' active' : ''}`} htmlFor={name}>
                        <LabelSpan className={this.handleIsActive(input, meta) ? 'active' : null}>
                          {label}
                          {!required && (
                            <Optional className="to-defaultinput__option-label">&#40; optionnel &#41;</Optional>
                          )}
                        </LabelSpan>
                        {change && change(input.onChange)}
                        {this.inputRender({ ...input, ...config }, meta)}
                      </Label>
                      {this.handleIsInValide(meta) ? (
                        <LabelError
                          className={`to-defaultinput__label-error${
                            this.handleIsInValide(meta) ? ' visible' : ' hidden'
                          }`}
                        >
                          {meta.error || (!meta.dirtySinceLastSubmit && meta.submitError)}
                        </LabelError>
                      ) : (
                        ''
                      )}
                    </LabelContainer>
                  )}
                </Field>
              )}
            </Field>
          )}
        </FormSpy>
      );
    }
  }

  CustomInput.contextType = AppContext;

  CustomInput.defaultProps = {
    validation: () => {},
    required: false,
    change: null,
    className: '',
    config: {},
  };

  CustomInput.propTypes = {
    validation: PropTypes.func,
    required: PropTypes.bool,
    change: PropTypes.func,
    className: PropTypes.string,
    config: PropTypes.shape({}),
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  };

  return CustomInput;
}

export default useInput;
