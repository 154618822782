import React from 'react';
import { validateName } from 'allianz-library';

import civilities from '../data/civilities';
import Input from './Input';
import Select from './Select';

const NameInputs = () => (
  <>
    <Select name="civility" label="Civilité" options={civilities} required />
    <Input name="lastName" label="Nom" validation={validateName} required />
    <Input name="firstName" label="Prénom" validation={validateName} required />
  </>
);

export default NameInputs;
