import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';

import AppContext from '../../App/App.context';
import { ButtonsContainer, ButtonSubmit, ButtonBack } from './styledComponents';

function FormButtons({ page, previous }) {
  const { isFormLocked, setCurrentStep } = useContext(AppContext);

  return (
    <FormSpy
      subscription={{
        invalid: true,
        values: true,
        touched: true,
        validating: true,
        submitting: true,
        modified: true,
      }}
    >
      {({ invalid, submitting, validating, validatingStep, values, modified }) => (
        <ButtonsContainer>
          {page !== 0 ? (
            <ButtonBack
              onClick={() => {
                previous();
                setCurrentStep(page);
              }}
            />
          ) : (
            <div />
          )}
          <ButtonSubmit
            type="submit"
            disabled={
              invalid ||
              submitting ||
              validatingStep ||
              (invalid && validating) ||
              (Object.values(modified).some(bool => bool === false) &&
                Object.keys(modified).length > Object.keys(values).length) ||
              Object.values(values).some(value => !value || (typeof value === 'string' && value.length === 0)) ||
              isFormLocked
            }
          >
            SUIVANT
          </ButtonSubmit>
        </ButtonsContainer>
      )}
    </FormSpy>
  );
}

FormButtons.propTypes = {
  page: PropTypes.number.isRequired,
  previous: PropTypes.func.isRequired,
};

export default FormButtons;
