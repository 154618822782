import React from 'react';
import { validateEmail, validatePhoneNumber } from 'allianz-library';

import ContactNotice from './ContactNotice';
import { LabelEt, InputWithoutRequiredIndicator } from './styledComponents';

const ContactInformations = () => (
  <>
    <ContactNotice />
    <InputWithoutRequiredIndicator name="email" label="E-mail" type="email" validation={validateEmail} required />
    <LabelEt>et</LabelEt>
    <InputWithoutRequiredIndicator
      name="phoneNumber"
      label="Téléphone Mobile"
      type="tel"
      validation={validatePhoneNumber}
      required
    />
  </>
);

export default ContactInformations;
