import React, { useContext, useEffect } from 'react';
import { Wizard } from 'timeone-components';
import styled from 'styled-components';

import AppContext from '../../App/App.context';
import FormButtons from '../FormButtons';
import Header from '../Header';
import useSteps from './useSteps';
import useStyles from './useStyles';
import getUrlParameter from '../../utils/getUrlParameter';
import buildInitialValues from '../../utils/buildInitialValues';

const WizardStyled = styled(Wizard)(({ css }) => css);
const ConfirmationContainer = styled.div(({ css }) => css);

const headerDisplay = getUrlParameter('partenaire') ? () => <></> : Header;

function StepsProvider() {
  const { currentStep, currentMediaConfig, setCurrentStep } = useContext(AppContext);
  const { confirmationContainerStyle, wizardStyle } = useStyles();
  const steps = useSteps();
  const wizardApi = {};

  useEffect(
    () => {
      if (currentStep === 3) {
        wizardApi.previous();
        setCurrentStep(currentStep - 1);
      }

      if (currentStep === 2) {
        if (currentMediaConfig !== 'large') {
          wizardApi.next();
          setCurrentStep(currentStep + 1);
        } else {
          wizardApi.previous();
          setCurrentStep(currentStep - 1);
        }
      }
    },
    // eslint-disable-next-line
    [currentMediaConfig]
  );

  let initialValues = buildInitialValues();

  return (
    <WizardStyled
      initialValues={initialValues}
      currentStep={currentStep}
      css={wizardStyle}
      onSubmit={() => {}}
      renderButtons={buttonsProps => {
        Object.assign(wizardApi, buttonsProps);
        return (
          buttonsProps.page !== steps.length - 1 && (
            <>
              <FormButtons {...buttonsProps} steps={steps} />
            </>
          )
        );
      }}
      renderHeader={headerDisplay}
      steps={steps}
    >
      {steps.map(({ name, render }) => (
        <Wizard.Page
          key={name}
          render={({ form }) => {
            if (initialValues) {
              setTimeout(() => {
                Object.entries(initialValues).map(entry => form.blur(entry[0]));
                initialValues = false;
              }, 0);
            }
            return currentStep === steps.length ? (
              <ConfirmationContainer css={confirmationContainerStyle}>{render()}</ConfirmationContainer>
            ) : (
              render()
            );
          }}
        />
      ))}
    </WizardStyled>
  );
}

export default StepsProvider;
