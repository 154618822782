import styled, { css } from 'styled-components';

// eslint-disable-next-line
export const ConfirmationContainer = styled.div`
  text-align: center;

  br {
    content: '';
    display: block;
    height: 1rem;
    width: 1rem;
  }

  h2 {
    font-weight: normal;
    margin: 0 0 1rem;
  }

  a {
    border-radius: 0.125rem;
    border: solid 0.0625rem ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.blue};
    display: block;
    font-size: 0.875rem;
    margin: 1rem auto;
    padding: 0.75rem 1.25rem;
    text-transform: uppercase;
    width: 10.5rem;

    &:hover {
      opacity: 0.875;
    }
  }

  ${({ theme }) =>
    theme.currentMediaConfig === 'large'
      ? css`
          padding: 2.5rem;

          h2 {
            font-size: 1.75rem;
          }

          p {
            font-size: 1.25rem;
            margin: 0 auto;
            max-width: 48rem;
          }

          svg {
            height: 6rem;
            margin: 3rem 0;
            width: 6rem;
          }
        `
      : css`
          padding: 0 2.5rem;

          svg {
            height: 4rem;
            margin: 3rem 0;
            width: 4rem;
          }
        `}
`;
