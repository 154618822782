import { useContext } from 'react';
import { css } from 'styled-components';

import AppContext from '../../App/App.context';

const styles = {
  large: {
    confirmationContainerStyle: css`
      padding: 4rem 8rem 2rem;
    `,
    wizardStyle: css`
      display: -ms-grid;
      display: grid;
      -ms-grid-rows: auto;
      -ms-grid-columns: 1fr 1fr 0.5fr 1fr 1fr;
      grid-template-columns: repeat(2, 1fr) 0.5fr repeat(2, 1fr);
      margin: 4rem 8rem 2rem;

      & > * {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(6),
        &:nth-child(10),
        &:nth-child(11),
        &:nth-child(12) {
          -ms-grid-column: 1;
          -ms-grid-column-span: 6;
          grid-column: 1 / 6;
        }

        &:nth-child(1) {
          -ms-grid-row: 1;
        }

        &:nth-child(2) {
          -ms-grid-row: 2;
        }

        &:nth-child(3) {
          -ms-grid-row: 3;
        }

        &:nth-child(4),
        &:nth-child(5) {
          -ms-grid-row: 4;
        }

        &:nth-child(6) {
          -ms-grid-row: 5;
        }

        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9) {
          -ms-grid-row: 6;
        }

        &:nth-child(10) {
          -ms-grid-row: 7;
        }

        &:nth-child(11) {
          -ms-grid-row: 8;
        }

        &:nth-child(12) {
          -ms-grid-row: 9;
        }

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(7) {
          -ms-grid-column: 1;
          -ms-grid-column-span: 2;
          grid-column: 1 / 3;
        }

        &:nth-child(8) {
          -ms-grid-column: 3;
          margin: 1.5rem 0 0;
          text-align: center;
        }

        &:nth-child(5),
        &:nth-child(9) {
          -ms-grid-column: 4;
          -ms-grid-column-span: 2;
          grid-column: 4 / 6;
        }
      }
    `,
  },
  small: {
    confirmationContainerStyle: css`
      margin: 4rem 2rem 2rem;
    `,
    wizardStyle: css`
      margin: 4rem 2rem 2rem;
    `,
  },
};

function useStyle() {
  const { currentMediaConfig } = useContext(AppContext);

  return currentMediaConfig === 'large' ? styles.large : styles.small;
}

export default useStyle;
