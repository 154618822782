import React from 'react';
import styled from 'styled-components';

import startupMembers from '../../assets/startupMembers.png';
import sansevieriaImg from '../../assets/sansevieria.png';
import deskLampImg from '../../assets/lamp.png';

export const HeaderContainer = styled.header`
  background-color: ${({ theme }) => theme.colors.secondary};
  height: ${({ theme }) => (theme.currentMediaConfig === 'large' ? '18rem' : '13.75rem')};
  margin-bottom: 6rem;
  padding: 2rem 0 0 0;
  position: relative;
  text-align: center;

  &::after {
    background: ${({ theme }) => theme.colors.secondary};
    border-radius: 4rem;
    content: '';
    display: block;
    height: 4rem;
    left: ${({ theme }) => (theme.currentMediaConfig === 'large' ? '12rem' : '2.5rem')};
    position: absolute;
    top: calc(100% - 2rem);
    width: calc(100% - ${({ theme }) => (theme.currentMediaConfig === 'large' ? '22rem' : '5rem')});
    z-index: -1;
  }
`;

export const ProgressBarContainer = styled.div`
  margin: 0 auto 1rem;
  width: calc(100% - 16rem);

  & > div:first-child {
    padding: 0;
  }
`;

export const StepIndicatorsContainer = styled.div`
  display: flex;
  height: 1.5rem;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
`;

export const StepLabel = styled.div`
  position: relative;

  &::after {
    content: '${({ content, index, stepsLength }) => `Étape ${index + 1}/${stepsLength} : ${content}`}';
    font-size: 0.875rem;
    font-weight: lighter;
    position: absolute;
    top: 0.5rem;
    ${({ index, stepsLength }) => index !== 0 && index + 1 !== stepsLength && `transform: translateX(-50%);`}
    ${({ index, stepsLength }) => index + 1 === stepsLength && `transform: translateX(-100%);`}
    white-space: nowrap;
  }
`;

export const StepIndicator = styled.span`
  color: ${({ theme }) => theme.colors.blue};
  font-weight: 600;
  margin: 0 0.5rem 0 0;
`;

export const MainTitle = styled.h1`
  margin: 1.5rem 0 0;
  max-height: ${({ theme }) => (theme.currentMediaConfig === 'large' ? `2.625rem` : `2.125rem`)};
  font-size: ${({ theme }) => (theme.currentMediaConfig === 'large' ? `2.25rem` : `1.75rem`)};
  text-transform: none;
`;

export const StartupMembersImg = styled(props => <img {...props} src={startupMembers} alt="entreprise members" />)`
  position: relative;
  top: ${({ theme }) => (theme.currentMediaConfig === 'large' ? `2.125rem` : `2.0625rem;`)};
  width: ${({ theme }) => (theme.currentMediaConfig === 'large' ? `18rem` : `12.375rem`)};
`;

export const SansevieriaImg = styled(props => <img {...props} src={sansevieriaImg} alt="sansevieria potted plant" />)`
  bottom: 0;
  ${({ theme }) =>
    theme.currentMediaConfig === 'large'
      ? `
        height: 8rem;
        left: 4rem;
      `
      : `
        height: 4rem;
        left: 0.75rem;
      `}
  position: absolute;
  width: auto;
`;

export const DeskLampImg = styled(props => <img {...props} src={deskLampImg} alt="lamp" />)`
  position: absolute;
  ${({ theme }) =>
    theme.currentMediaConfig === 'large'
      ? `
        bottom: -1.375rem;
        right: 2rem;
        width: 12rem;
      `
      : `
        bottom: -0.5rem;
        right: 0.25rem;
        width: 4rem;
      `}
`;
