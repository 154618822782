import { themes } from 'timeone-components';

const { timeone } = themes;

const colors = {
  ...timeone.colors,
  primary: '#414141',
  secondary: '#c0ddbd',
  blue: '#007ab3',
};

export default {
  ...timeone,
  colors,
  progressBar: {
    backgroundColor: '#ececec',
    borderRadius: '0',
    containerPadding: '0',
    fillerBackgroundColor: colors.blue,
    height: '0.25rem',
    marginBottom: '0',
  },
};
