import React from 'react';
import PropTypes from 'prop-types';

import { ConfirmationContainer } from './styledComponents';

const Confirmation = ({ className, children }) => (
  <ConfirmationContainer className={className}>{children}</ConfirmationContainer>
);

Confirmation.defaultProps = {
  className: '',
  children: null,
};

Confirmation.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element,
};

export default Confirmation;
