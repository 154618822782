import getUrlParameter from './getUrlParameter';
import products from '../data/products';
import civilities from '../data/civilities';

export default function buildInitialValues() {
  const productSelected = products.filter(product => product.value === getUrlParameter('produit')).pop();
  const civilitySelected = civilities.filter(civility => String(civility.value) === getUrlParameter('civilite')).pop();

  return Object.entries({
    firstName: getUrlParameter('prenom'),
    lastName: getUrlParameter('nom'),
    siren: getUrlParameter('siren'),
    email: getUrlParameter('email'),
    phoneNumber: getUrlParameter('telephone'),
    product: productSelected,
    civility: civilitySelected,
  })
    .filter(value => !!value[1])
    .reduce((reducer, val) => ({ ...reducer, [val[0]]: val[1] }), {});
}
