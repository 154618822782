import React from 'react';
import { validateWithApi } from 'allianz-library';

import products from '../../data/products';
import Input from '../Input';
import NameInputs from '../NameInputs';
import Select from '../Select';

function validateSiren(value) {
  return validateWithApi(
    'https://lmt.timeonegroup.com/api/validation/sirene/siren',
    value,
    'Merci de saisir un numéro de SIREN valide.'
  );
}

function ProductInformations() {
  return (
    <>
      <Input name="siren" label="SIREN" validation={validateSiren} required />
      <Select name="product" label="Votre besoin" options={products} isSearchable required />
      <NameInputs />
    </>
  );
}

export default ProductInformations;
